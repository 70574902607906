.page-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-bottom: 0px;
    padding-top: 0px;
    width: 100%;
}

.page-header {
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
    padding-bottom: 60px;
    padding-top: 60px;
    width: 100%;
}

.title {
    color: #191919;
    font-family: ftnk;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    padding-bottom: 20px;
    padding-top: 0px;
    text-align: center;
    text-transform: none;
    width: 100%;
}

.title-description {
    color: #999999;
    font-family: ftnk;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding-bottom: 0px;
    padding-top: 0px;
    text-align: center;
    text-transform: none;
    width: 100%;
}

.portrait-container {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    padding-top: 0px;
    max-width: 100%;
}

.portrait-container img {
    display: block;
    margin: 0 auto;
    max-width: 453px;
}

.about-container {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
    padding-top: 20px;
    max-width: 800px;
    width: 100%;
}

.about {
    color: rgb(0,0,0);
    font-family: ftnk;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    height: auto;
    line-height: 20px;
    padding-bottom: 10px;
    padding-top: 0px;
    text-align: left;
    text-transform: none;
    text-rendering: optimizeLegibility;
}

.subtitle-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    width: 100%;
}

.subtitle {
    color: rgb(0,0,0);
    font-family: ftnk;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    text-transform: none;
}

.subtitle-description {
    color: rgb(0,0,0);
    font-family: ftnk;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    text-transform: none;
}

.media-container {
    clear: both;
    overflow: hidden;
    padding-bottom: 40px;
    padding-top: 0px;
    max-width: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.media-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 10px;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}