.site-header {
  -webkit-align-items: center;
  align-items: center;
  background-color: #FFFFFF;
  border-bottom: 15px solid #FFFFFF;
  height: 100px;
  padding-bottom: 0px;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 30px;
  display: flex;
  position: relative;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.logo-wrap {
  max-width: 100%;
  margin-right: auto;
  padding-bottom: 0px;
  padding-top: 0px;
  width: 40%;
  display:flex;
  align-content: center;
  justify-content: center;
}

  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
  }

  .navbar-logo img {
    width: 65px;
    height: 65px;
  }

.nav-social {
  display: flex;
}

  .nav-social-icons {
    list-style: none;
    display:flex;
    flex-direction: row;
  }

    .linkedin-icon img {
      width: 28px;
      height: 28px;
      padding-left: 15px;
    }

    .mail-icon img {
      width: 28px;
      height: 28px;
      padding-left: 15px;
    }

.navbar-container {
  padding-left: 0%;
  padding-right: 0%;
  text-align: left;
  float: left;
  max-width: 100%;
  width: 30%;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 5px;
  list-style: none;
  text-align: center;
  justify-content: flex-start;
}

.nav-item {
  height: 80px;
}

.nav-item-linkedin {
  display: none;
}

.nav-item-linkedin img {
  width: 30px;
  height: 30px;
}

.nav-item-mail {
  display: none;
}

.nav-item-mail img {
  width: 30px;
  height: 30px;
}

.nav-links {
  color:rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  color:rgba(0,0,0,1);
  transition: all 0.2s ease-out;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {


  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item-linkedin {
    display: inherit;
  }

  .nav-item-mail {
    display: inherit;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color:rgba(0,0,0,1);
    border-radius: 0;
  }

  .navbar-logo {
    position: flex;
    top: 0;
    left: 0;
    
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .nav-social {
    display: none;
  }


}

