.site-container {
  position: relative;
  width: 100%;
  float: none;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.site-content {
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
}